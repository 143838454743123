import React from 'react';

import './auth-error-msg.css';
import { useTranslation } from 'react-i18next';

function AuthErrorMsg() {
  const { t } = useTranslation();

  return (
    <div className="auth-error-msg">
      <h1>{t('login_error')}</h1>
      <p>
        {t('try_login_again')}
        {' '}
        {t('contact_support_error')}
      </p>
    </div>
  );
}

export default AuthErrorMsg;
