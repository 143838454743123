import { SummaryData } from './util/utilService';

import {
  Info as RecordingInfo,
  RecordingUrlInfo,
  TranscriptDetails,
} from './models/types';

import axiosInstance from '../../../utils/axiosInstance';
import { Todo } from '../todos/types';

export const fetchConversations = async ({
  page, sessionId = null, searchTerm = '',
}: {
  page: number,
  sessionId: string | null,
  searchTerm: string,
}): Promise<RecordingInfo[]> => {
  const url = `switchboard/conversations?page=${page}${
    sessionId ? `&session_id=${sessionId}` : ''
  }${
    searchTerm ? `&search=${searchTerm}` : ''
  }`;
  const response = await axiosInstance.get<{ data: RecordingInfo[] }>(url);
  return response.data.data;
};

export const fetchTranscriptSummaries = async (
  sessionId: string,
): Promise<SummaryData> => {
  try {
    const response = await axiosInstance.get<SummaryData>(
      `recordings/sessions/${sessionId}/transcript/summaries`,
    );

    return response.data;
  } catch (e: any) {
    return e.message;
  }
};

export const fetchTodosBySession = async (
  accountId: string,
  sessionId?: string,
): Promise<Todo[] | undefined> => {
  try {
    const searchParams = new URLSearchParams();
    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const query = searchParams.toString() ? `?${searchParams.toString()}` : '';
    const url = `account/${accountId}/categorization/todos${query}`;
    const response = await axiosInstance.get<Todo[]>(url);

    return response.data;
  } catch (e: any) {
    return e.message;
  }
};

export const fetchRecordingTranscript = async (
  sessionId: string,
): Promise<TranscriptDetails> => {
  const response = await axiosInstance.get<{ data: TranscriptDetails }>(
    `recordings/sessions/${sessionId}/transcript`,
  );

  return response.data.data;
};

export const fetchRecordingBySession = async (
  sessionId: string,
): Promise<RecordingUrlInfo> => {
  const response = await axiosInstance.get<{ data: RecordingUrlInfo }>(
    `recordings/sessions/${sessionId}`,
  );

  return response.data.data;
};

export const switchCallPrivacyType = async (
  sessionId: string,
): Promise<RecordingUrlInfo> => {
  const response = await axiosInstance.post<RecordingUrlInfo>(
    `sessions/${sessionId}/meetings/switch-to-business`,
  );

  return response.data;
};

export const deleteMeetingUsingSessionId = async (
  sessionId: string,
  accountId: string,
) => {
  const response = await axiosInstance.delete(
    `/accounts/${accountId}/stats/meetings/${sessionId}`,
  );

  return response.data;
};

export async function startLiveRecordingUpload({
  meetingId, title, participants,
}: {
  meetingId: string | null,
  title: string,
  participants: any[]
}) {
  const response = await axiosInstance.post(
    '/recordings/upload-live',
    { meetingId, title, participants },
  );
  return response.data;
}

export async function getLiveRecordingUploadUrls(
  recordingId: string,
  uploadId: string,
  offset = 1,
  page = 50,
) {
  const response = await axiosInstance.get(
    `/recordings/${recordingId}/upload-live-urls?uploadId=${uploadId}&offset=${offset}&page=${page}`,
  );
  return response.data;
}

async function finishOrFailLiveRecordingUpload({
  recordingId,
  uploadId,
  partETags,
  recordingDuration,
  success,
}: {
  recordingId: string,
  uploadId: string,
  success: boolean,
  partETags?: string[],
  recordingDuration?: number,
}) {
  const response = await axiosInstance.put(
    `/recordings/upload-live/${recordingId}`,
    {
      success, uploadId, partETags, recordingDuration,
    },
  );
  return response.data;
}

export function endLiveRecordingUpload({
  recordingId,
  uploadId,
  partETags,
  recordingDuration,
}: {
  recordingId: string,
  uploadId: string,
  partETags: string[],
  recordingDuration: number,
}) {
  return finishOrFailLiveRecordingUpload({
    recordingId,
    uploadId,
    partETags,
    recordingDuration,
    success: true,
  });
}

export function failLiveRecordingUpload(recordingId: string, uploadId: string) {
  return finishOrFailLiveRecordingUpload({
    recordingId,
    uploadId,
    success: false,
  });
}

export async function getTodaysMeetings(userId: string) {
  const today = new Date().toISOString().substring(0, 10);
  const url = `/users/${userId}/meetings`
    + `?start_time=${today}&end_time=${today}&users[]=${userId}&include_without_bot=1`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// TODO: fix this insanity and keep generators restricted to sagas
export async function editMeetingAsync(
  accountId: string,
  meetingId: string,
  meeting: any,
) {
  const { data } = await axiosInstance
    .put(
      `/accounts/${accountId}/meetings/${meetingId}`,
      JSON.stringify(meeting),
    );
  return data;
}

export async function updateMeetingParticipants(meetingId: string, participants: any[]) {
  const { data } = await axiosInstance
    .put(
      `/meetings/${meetingId}/receivers`,
      JSON.stringify(participants),
    );
  return data;
}
