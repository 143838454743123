/* eslint-disable */

import React, { Component, createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
  startLiveRecordingUpload,
  getLiveRecordingUploadUrls,
  endLiveRecordingUpload,
  failLiveRecordingUpload,
  getTodaysMeetings,
  editMeetingAsync,
  updateMeetingParticipants,
} from 'store/features/recordings/recordingsAPI';
import { fetchUser } from 'store/features/user/userSlice';
import { streamToS3Urls, detectWhenDisabled, firstPartFailedError } from './helperFunctions';
import { EditParticipantsList, validateEmail } from './EditParticipantsList';
import UserGreen from '../../components/ui/icons/User/UserGreen';
import UserBlue from '../../components/ui/icons/User/UserBlue';
import { formatTime } from '../../components/conversation/RecordingTranscript/RecordingTranscript';
import Loading from '../../components/ui/ui-feedback/LoadingComponent/LoadingComponent';
import ConfirmationModal from '../../components/ui/confirmation-modal';
import InfoModal from '../../components/ui/info-modal/InfoModal';
import Button from '../../components/ui/button';
import Checkbox from '../../components/ui/checkbox';
import Input from '../../components/ui/input';
import './live-recording.css';

const initBem = function initBem(block: string) {
  return (element?: string, ...modifiers: string[]) => {
    let className = block;
    if (element){
      className += `__${element}`;
    }
    if (modifiers.length) {
      className = ['', ...modifiers].map(modifier => `${className}--${modifier}`).join(' ');
    }
    return className;
  };
};

type LiveRecordingProps = {
  currentUser: any;
  t?: any;
};

type LiveRecordingState = {
  meetingTitle: string;
  isMicrophoneEnabled: boolean;
  isCameraEnabled: boolean;
  screenSharingAvailable: boolean;
  isScreenSharingEnabled: boolean;
  microphoneStream: MediaStream|null;
  cameraStream: MediaStream|null;
  screenSharingStream: MediaStream|null;
  isCameraMainView: boolean;
  isRecordingStarting: boolean;
  durationTimerTickTimeout: any;
  jointStream: MediaStream|null;
  mediaRecorder: MediaRecorder|null;
  showingInfoModal: boolean;
  participants: any[];
  meetingsToday: any[]|null;
  meetingsSearch: string;
  isMeetingSelected: boolean;
  selectedMeetingId: string|null;
  meetingFromApi: any;
  areReceiversModified: boolean;
  isMeetingDone: boolean;
  showingConfirmStopModal: boolean;
  viewUrl: string|undefined;
  recordingDuration: number;
  showingErrorModal: boolean;
  errorModalMessage: any;
  recordingId: string|null;
  sessionId: string|null;
  uploadId: string|null;
  redrawVideoTimeout: any;
};

const VIDEO_WIDTH = 1280;
const VIDEO_HEIGHT = 720;

const bem = initBem('live-recording');

const initialState = {
  meetingTitle: '',
  isMicrophoneEnabled: false,
  isCameraEnabled: false,
  screenSharingAvailable: false,
  isScreenSharingEnabled: false,
  microphoneStream: null,
  cameraStream: null,
  screenSharingStream: null,
  isCameraMainView: true,
  isRecordingStarting: false,
  durationTimerTickTimeout: null,
  jointStream: null,
  mediaRecorder: null,
  showingInfoModal: false,
  participants: [],
  meetingsToday: null,
  meetingsSearch: '',
  isMeetingSelected: false,
  selectedMeetingId: null,
  meetingFromApi: null,
  areReceiversModified: false,
  isMeetingDone: false,
  showingConfirmStopModal: false,
  viewUrl: undefined,
  recordingDuration: 0,
  showingErrorModal: false,
  errorModalMessage: <></>,
  recordingId: null,
  sessionId: null,
  uploadId: null,
  redrawVideoTimeout: null,
};

class LiveRecording extends Component<LiveRecordingProps, LiveRecordingState> {
  lastFrameMs: number = 0;
  cameraVideoElementRef: React.RefObject<any> = createRef();
  previewVideoPlayerRef: React.RefObject<any> = createRef();
  canvasElementRef: React.RefObject<any> = createRef();
  // TODO: everything related to audioRef is a hack for throttling, find better fix and remove
  audioRef: React.RefObject<any> = createRef();

  constructor(props: any) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = () => {
    if (this.props.currentUser?.id) {
      this.fetchTodaysMeetings();
    }

    const canvas = this.canvasElementRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'black';

    setTimeout(this.drawVideo, 40);
    window.addEventListener('beforeunload', this.onBeforeUnload);
  };

  drawVideo = () => {
    this.setState({
      redrawVideoTimeout: setTimeout(this.drawVideo, 40),
    });
    const ctx = this.canvasElementRef.current?.getContext('2d');
    if (!ctx) return;
    ctx.fillRect(0, 0, this.canvasElementRef.current.width, this.canvasElementRef.current.height);
    if (this.state.cameraStream) {
      ctx.drawImage(this.cameraVideoElementRef.current, 0, 0);
    }
  };

  componentWillUnmount = () => {
    if (this.state.mediaRecorder) {
      this.stopRecording(false);
      this.failAnyUploadsInProgress();
    }
    clearTimeout(this.state.redrawVideoTimeout);
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  };

  componentDidUpdate = (prevProps: Readonly<LiveRecordingProps>, prevState: any) => {
    if (this.props.currentUser?.id
        && this.props.currentUser?.id !== prevProps.currentUser?.id) {
      this.fetchTodaysMeetings();
    }
  };

  onBeforeUnload = (event: BeforeUnloadEvent) => {
    if (
      this.state.isRecordingStarting
      || this.state.mediaRecorder
      || this.state.participants.length > 0
    ) {
      event.preventDefault();
      event.returnValue = 'Recording in progress';
      return true;
    }
  };

  failAnyUploadsInProgress = () => {
    if (this.state.isRecordingStarting || this.state.mediaRecorder) {
      const { recordingId, uploadId } = this.state;
      failLiveRecordingUpload(recordingId!, uploadId!);
    }
  };

  fetchTodaysMeetings = async () => {
    const { data: meetingsToday } = await getTodaysMeetings(this.props.currentUser.id);
    this.setState({ meetingsToday });
  };

  startRecording = async () => {
    await this.pruneParticipants();
    this.setState({
      isRecordingStarting: true,
      areReceiversModified: false,
    });

    const audio = this.audioRef.current;
    if (audio) {
      audio.volume = 0.002;
      audio.play();
    }

    let wakeLock = null;
    try {
      // @ts-ignore
      wakeLock = await window.navigator.wakeLock?.request();
    } catch (e) {}

    this.disableNavigation()

    try {
      var {
        recording,
        meeting,
        uploadId,
        uploadUrls: signedS3UploadUrls,
        viewUrl,
      } = await startLiveRecordingUpload({
        // NOTE: disabling attaching to existing meeting
        meetingId: null, // this.state.selectedMeetingId,
        title: this.state.meetingTitle,
        participants: this.state.participants.map(({ tempId, ...participant }) => participant),
      });

      this.setState({
        meetingFromApi: meeting,
        selectedMeetingId: meeting.id,
        meetingTitle: meeting.name,
        participants: this.mapReceiversToParticipants(meeting.receivers),
        recordingId: recording.id,
        sessionId: recording.session_id,
        uploadId,
        viewUrl,
      });
    } catch (e) {
      this.stopCamera();
      this.stopMicrophone(true);

      this.resetState();
      this.setState({
        showingErrorModal: true,
        errorModalMessage: <>
          {t('error_start')}
          <br/>
          {t('contact_support_error')}
        </>,
      });
      this.enableNavigation();
      throw e;
    }

    try {
      const jointStream = this.createJointStream();
      const mediaRecorder = new MediaRecorder(jointStream);

      const componentInstance = this;
      let recordingStart = window.performance.now();
      let durationTimerTickTimeout = setTimeout(function tick() {
        const recordingDurationInMs = window.performance.now() - recordingStart;
        const recordingDuration = recordingDurationInMs / 1000 | 0;

        componentInstance.setState({ recordingDuration });
        durationTimerTickTimeout = setTimeout(tick, 1000 - (recordingDurationInMs % 1000));
        componentInstance.setState({ durationTimerTickTimeout });
      });

      this.setState({
        jointStream,
        mediaRecorder,
        durationTimerTickTimeout,
        isRecordingStarting: false,
      });

      const partETags = await streamToS3Urls(
        mediaRecorder,
        signedS3UploadUrls,
        (offset: number, page: number) => {
          return getLiveRecordingUploadUrls(recording.id, uploadId!, offset, page);
        }
      );
      clearTimeout(durationTimerTickTimeout);

      const recordingDuration = (window.performance.now() - recordingStart) / 1000;
      await endLiveRecordingUpload({
        recordingId: recording.id,
        uploadId: uploadId!,
        partETags,
        recordingDuration,
      });

      this.setState({
        showingInfoModal: true,
        isMeetingDone: true,
      });
    } catch (e) {
      console.error(e);
      this.stopRecording(false);
      failLiveRecordingUpload(this.state.recordingId!, this.state.uploadId!);

      this.resetState();
      this.setState({
        showingErrorModal: true,
        errorModalMessage: <>
          <p>{t('error_nonspecific')}</p>
          {e !== firstPartFailedError && (
            <p>{t('error_recovery')}</p>
          )}
          <p>{t('contact_support_error')}</p>
        </>,
      });
    } finally {
      this.enableNavigation();
      this.setState({
        isRecordingStarting: false,
        durationTimerTickTimeout: null,
      });
      clearTimeout(this.state.durationTimerTickTimeout);
      wakeLock?.release();
      this.audioRef.current?.pause();
    }
  };

  createJointStream = () => {
    const { microphoneStream } = this.state;
    const jointStream = new MediaStream([
      ...microphoneStream!.getTracks(),
      ...this.canvasElementRef.current.captureStream(24).getTracks(),
    ]);
    return jointStream;
  };

  stopRecording = async (isButtonClick: boolean) => {
    this.stopMicrophone(true);
    this.stopCamera();
    const { durationTimerTickTimeout, mediaRecorder, jointStream } = this.state;
    clearTimeout(durationTimerTickTimeout);
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = null;
      mediaRecorder.stop();
    }
    this.setState({
      jointStream: null,
      mediaRecorder: null,
      isRecordingStarting: isButtonClick,
    });
    await new Promise(resolve => setTimeout(resolve, 500));
    jointStream?.getTracks().forEach(track =>
      jointStream?.removeTrack(track)
    );
  };

  toggleMicrophone = async () => {
    if (this.state.isMicrophoneEnabled) {
      this.stopMicrophone(false);
    } else {
      this.startMicrophone();
    }
  };

  startMicrophone = async () => {
    this.setState({ isMicrophoneEnabled: true });
    if (this.state.jointStream) {
      this.state.microphoneStream?.getTracks().forEach(track => track.enabled = true);
      return;
    }

    try {
      const microphoneStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.setState({ microphoneStream });
      detectWhenDisabled(microphoneStream, () => this.stopMicrophone(false));
    } catch (error) {
      this.setState({
        isMicrophoneEnabled: false,
        showingErrorModal: true,
        errorModalMessage: t('could_not_access_microphone'),
      });
    }
  };

  stopMicrophone = (forceRelease: boolean) => {
    this.setState({ isMicrophoneEnabled: false });
    if (this.state.jointStream && !forceRelease) {
      this.state.microphoneStream?.getTracks().forEach(track => track.enabled = false);
    } else {
      this.state.microphoneStream?.getTracks().forEach(track => track.stop());;
      this.setState({ microphoneStream: null });
    }
  };

  toggleCamera = async () => {
    if (this.state.isCameraEnabled) {
      this.stopCamera();
    } else {
      this.startCamera();
    }
  };

  startCamera = async () => {
    try {
      this.setState({ isCameraEnabled: true });
      const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true });
      detectWhenDisabled(cameraStream, () => this.setState({
        isCameraEnabled: false,
        cameraStream: null,
      }));
      this.setState({ cameraStream });

      const canvas = this.canvasElementRef.current;
      this.cameraVideoElementRef.current.srcObject = cameraStream;
      this.cameraVideoElementRef.current.onloadedmetadata = async function () {
        await this.play();
        canvas.width = this.videoWidth;
        canvas.height = this.videoHeight;
      };
    } catch (error) {
      this.setState({
        isCameraEnabled: false,
        showingErrorModal: true,
        errorModalMessage: t('could_not_access_camera')
      });
    }
  };

  stopCamera = () => {
    this.cameraVideoElementRef.current.srcObject = null;
    this.state.cameraStream?.getTracks().forEach(track => track.stop());

    this.setState({
      isCameraEnabled: false,
      cameraStream: null,
      isCameraMainView: !this.state.screenSharingStream,
    });
  };

  updateMeetingTitle = (meetingTitle: string) => {
    this.setState({ meetingTitle });
  }

  updateParticipants = (participants: any[], callback: any = () => {}) => {
    const areReceiversModified = this.state.isMeetingSelected && !!this.state.selectedMeetingId;
    this.setState({ participants, areReceiversModified }, callback);
  };

  pruneParticipants = () => {
    return new Promise(resolve => {
      this.updateParticipants(
        this.state.participants.filter(
          participant => participant.email || participant.firstName || participant.lastName || participant.phone
        ),
        resolve,
      );
    });
  };

  updateMeetingOnApi = async () => {
    const titleModified = this.state.meetingTitle !== this.state.meetingFromApi?.name;
    if (titleModified) {
      const { receivers = null, new_receivers = null, ...meetingFromApi } = {
        ...this.state.meetingFromApi,
        name: this.state.meetingTitle,
      };

      this.setState({ meetingFromApi });
      editMeetingAsync(
        this.props.currentUser.accountId!,
        this.state.selectedMeetingId!,
        meetingFromApi,
      );
    }

    await this.pruneParticipants();
    if (this.state.areReceiversModified) {
      this.setState({ areReceiversModified: false });
      const { data } = await updateMeetingParticipants(
        this.state.selectedMeetingId!,
        this.state.participants,
      );
      this.setState({ participants: this.mapReceiversToParticipants(data) });
    }
  };

  // In this context "participants" are camelCased, "receivers" snake_cased
  mapReceiversToParticipants = (receivers: any[]) => {
    return receivers.map((receiver: any) => ({
      id: receiver.id,
      firstName: receiver.first_name || '',
      lastName: receiver.last_name || '',
      email: receiver.email || '',
      phone: receiver.phone || '',
    }));
  };

  selectMeeting = (meeting: any) => {
    this.setState({
      isMeetingSelected: true,
      selectedMeetingId: meeting?.id || null,
      meetingTitle: meeting?.name || '',
      participants: meeting?.receivers
        ? this.mapReceiversToParticipants(meeting.receivers)
        : [],
    });
  };

  getDisabledReceiversIds = () => {
    if (!this.state.isMeetingSelected) return [];
    return this.state.meetingsToday
      ?.find(({ id }) => id === this.state.selectedMeetingId)
      ?.meeting_receivers
      ?.filter(({ is_added_later }: any) => !is_added_later)
      .map(({ receiver }: any) => receiver.id)
    || [];
  }

  unselectMeeting = () => {
    this.setState({
      isMeetingSelected: false,
      selectedMeetingId: null,
      meetingTitle: '',
      participants: [],
      areReceiversModified: false,
    });
  };

  renderParticipants = () => {
    const { participants } = this.state;
    return participants.map(participant => (
      <div key={participant.id || participant.tempId} className={bem('participant-data')}>
        <span className={bem('participant-name')}>
          {participant.firstName}
          {participant.firstName && participant.lastName && ' '}
          {participant.lastName}
        </span>
        {' '}
        {participant.email && <span className={bem('participant-email')}>{participant.email}</span>}
        {' '}
        {participant.phone && <span className={bem('participant-phone')}>{participant.phone}</span>}
      </div>
    ));
  };

  anyInvalidParticipantData = () => {
    return this.state.participants?.some(({ email }) => email && !validateEmail(email));
  };

  resetState = () => {
    this.setState(initialState, () => {
      this.fetchTodaysMeetings();
    });
  };

  confirmStop = () => {
    this.setState({
      showingConfirmStopModal: true,
    });
  };

  getFilteredMeetingsToday = () => {
    const { meetingsSearch, meetingsToday } = this.state;
    const lowercaseSearch = meetingsSearch.toLowerCase();
    return meetingsToday!.filter(meeting =>
      !lowercaseSearch
        || lowercaseSearch.split(' ').every(word =>
          meeting.name.toLowerCase().includes(word)
            || meeting.created_by.first_name?.toLowerCase().includes(word)
            || meeting.created_by.last_name?.toLowerCase().includes(word)
            || meeting.created_by.email?.toLowerCase().includes(word)
            || meeting.created_by.phone?.toLowerCase().includes(word)
            || meeting.receivers?.some((receiver: any) =>
              receiver.first_name?.toLowerCase().includes(word)
                || receiver.last_name?.toLowerCase().includes(word)
                || receiver.email?.toLowerCase().includes(word)
                || receiver.phone?.toLowerCase().includes(word)
            )
        )
    );
  };

  isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  disableNavigation() {
    // TODO: when router is fully added, use a guard
    const buttonsToDisable = document.querySelectorAll('.nav-item, .header__menu-button');
    buttonsToDisable.forEach(button => {
      button.style.pointerEvents = 'none';
      const icon = button.querySelector('svg');
      if (icon) {
        icon.style.color = 'lightgray';
      }
    });
  }

  enableNavigation() {
    const buttonsToEnable = document.querySelectorAll('.nav-item, .header__menu-button');
    buttonsToEnable.forEach(button => {
      button.style.pointerEvents = '';
      const icon = button.querySelector('svg');
      if (icon) {
        icon.style.color = '';
      }
    });
  }

  render() {
    const {
      meetingTitle,
      microphoneStream,
      isCameraEnabled,
      isRecordingStarting,
      isMicrophoneEnabled,
      mediaRecorder,
      showingInfoModal,
      participants,
      meetingsToday,
      meetingsSearch,
      isMeetingSelected,
      areReceiversModified,
      isMeetingDone,
      recordingDuration,
      showingConfirmStopModal,
      viewUrl,
      meetingFromApi,
      showingErrorModal,
      errorModalMessage,
    } = this.state;

    const recordingInitiated = !!(isRecordingStarting || mediaRecorder || isMeetingDone);
    const anyInvalidParticipantData = this.anyInvalidParticipantData();

    let disabledStartButtonTooltip = null;
    if (!isRecordingStarting) {
      if (!isMicrophoneEnabled && !isMeetingSelected) {
        disabledStartButtonTooltip = t('unmute_microphone_select_meeting');
      } else if (!isMicrophoneEnabled) {
        disabledStartButtonTooltip = t('unmute_microphone');
      } else if (!isMeetingSelected) {
        disabledStartButtonTooltip = t('select_meeting');
      } else if (anyInvalidParticipantData) {
        disabledStartButtonTooltip = t('participants_emails_invalid');
      }
    }

    let disabledStopButtonTooltip = null;
    if (anyInvalidParticipantData) {
      disabledStopButtonTooltip = t('participants_emails_invalid');
    }

    const canUpdateMeeting = this.props.currentUser
      && !isRecordingStarting
      && !(!areReceiversModified && meetingTitle === meetingFromApi?.name)
      && !anyInvalidParticipantData;

    return (
      <div className={bem('page')}>
        <div className={bem('content')}>
          <audio ref={this.audioRef} loop preload="metadata" data-durationhint="409" playsInline>
            <source src="/whitenoise.mp3" type="audio/mpeg" data-transcodekey="mp3" />
          </audio>

          <div className={bem('meetings-data')}>
            {isMeetingSelected ? (
              <>
                {!isMeetingDone && !recordingInitiated && (
                  <Button
                    type="button"
                    className="button secondary-button select-another-meeting"
                    description={`‹ ${t('select_another_meeting')}`}
                    handleClick={this.unselectMeeting}
                  />
                )}
                <Input
                  type="text"
                  className="meeting-title"
                  value={meetingTitle}
                  isRequired={false}
                  placeholder={t('meeting_title')}
                  onChange={(e: any) => this.updateMeetingTitle(e.target.value)}
                />
                <EditParticipantsList
                  participants={participants}
                  onUpdate={this.updateParticipants}
                  disabledParticipantIds={this.getDisabledReceiversIds()}
                  t={t}
                />
                {recordingInitiated && (
                  <div
                    className="button-tooltip-container update-meeting-button-container"
                    {...(
                      anyInvalidParticipantData
                        ? { 'data-tooltip': t('participant_invalid_data') }
                        : {}
                    )}
                  >
                    <Button
                      type="button"
                      className="button update-meeting"
                      disabled={!canUpdateMeeting}
                      description={t('update')}
                      handleClick={this.updateMeetingOnApi}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {!!meetingsToday?.length && <h4 className={bem('todays-meetings-title')}>{t('todays_meetings')}</h4>}
                <div className={bem('todays-meetings')}>
                  {meetingsToday ? (
                    meetingsToday.length ? (
                      <div className={bem('todays-meetings-list')}>
                        <Input
                          type="text"
                          value={meetingsSearch}
                          isRequired={false}
                          placeholder={t('search_meetings')}
                          onChange={(e: any) => this.setState({ meetingsSearch: e.target.value })}
                        />
                        <div className={bem('todays-meetings-scrollable')}>
                          {this.getFilteredMeetingsToday()
                            .map(meeting => (
                              <div key={meeting.id} className={bem('todays-meeting-item')} onClick={() => this.selectMeeting(meeting)}>
                                <div className={bem('todays-meeting-left')}>
                                  <h5 className={bem('todays-meeting-start-time')}>
                                    {new Date(meeting.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                  </h5>
                                  <div className={bem('todays-meeting-name')}>
                                    {meeting.name}
                                  </div>
                                  <hr />
                                  <div className={bem('todays-meeting-creator')}>
                                    <UserGreen />
                                    {[
                                      meeting.created_by.first_name,
                                      meeting.created_by.last_name,
                                    ].join(' ')}
                                  </div>
                                </div>

                                <div className={bem('todays-meeting-right')}>
                                  {meeting.receivers?.length ? meeting.receivers.map((receiver: any) => (
                                    <div key={receiver.id} className={bem('todays-meeting-receiver')}>
                                      <UserBlue />
                                      {[receiver.first_name, receiver.last_name].join(" ").trim() || receiver.email}
                                      {receiver.company && ` (${receiver.company})`}
                                    </div>
                                  )) : (
                                    <span>({t('no_participants')})</span>
                                  )}
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ) : (
                      <div className={bem('no-meetings-message')}>{t('no_meetings_today')}</div>
                    )
                  ) : (
                    <>
                      <div className={bem('loading-meetings-message')}>{t('loading_meetings')}</div>
                      <Loading />
                    </>
                  )}

                  <Button
                    type="button"
                    className="button new-meeting"
                    description={`＋ ${t('new_meeting')}`}
                    key={null}
                    handleClick={() => this.selectMeeting(null)}
                  />
                </div>
              </>
            )}
          </div>

          <div className={bem('header') + (mediaRecorder ? ' recording' : '') + (isMeetingDone ? ' meeting-finished' : '')}>
            {isMeetingDone ? (
              <h2 className={bem('title')}>{t('meeting_finished')}</h2>
            ) : !!mediaRecorder ? (
              <h2 className={bem('title')}>{t('recording')} &nbsp; {formatTime(recordingDuration)}</h2>
            ) : (
              <h2 className={bem('title')}>{t('notetaker_live')}</h2>
            )}
            {isMeetingDone ? (
              <Button
                type="button"
                handleClick={this.resetState}
                description={t('start_new_meeting')}
              />
            ) : !!mediaRecorder ? (
              <div className="button-tooltip-container"
                {...(disabledStopButtonTooltip ? { 'data-tooltip': disabledStopButtonTooltip } : {})}
              >
                <Button
                  type="button"
                  className="button stop-recording"
                  handleClick={this.confirmStop}
                  disabled={!!disabledStopButtonTooltip || isRecordingStarting}
                  description={t('stop_recording')}
                />
              </div>
            ) : (
              <div
                  className="button-tooltip-container"
                  {...(disabledStartButtonTooltip ? { 'data-tooltip': disabledStartButtonTooltip } : {})}
              >
                <Button
                  type="button"
                  handleClick={this.startRecording}
                  disabled={!!disabledStartButtonTooltip || isRecordingStarting}
                  description={isRecordingStarting
                    ? (this.state.durationTimerTickTimeout ? t('saving') : t('starting'))
                    : t('start_recording')}
                />
              </div>
            )}
          </div>

          {!isMeetingDone && (
            <div className={bem('recording-in-progress-preview')}>
              {!!mediaRecorder && !isMicrophoneEnabled && (
                <span className={bem('video-warning')}>
                  {t('microphone_muted')}
                </span>
              )}

              <video
                className={bem('meeting-video')}
                ref={this.cameraVideoElementRef}
              ></video>

              <canvas width={VIDEO_WIDTH} height={VIDEO_HEIGHT} ref={this.canvasElementRef}></canvas>

              <div className={bem('video-switches')}>
                <Checkbox
                  text={t('microphone')}
                  handleCheckboxClick={this.toggleMicrophone}
                  handleTextClick={this.toggleMicrophone}
                  done={isMicrophoneEnabled}
                  disabled={isRecordingStarting}
                />
                <Checkbox
                  text={t('camera')}
                  handleCheckboxClick={this.toggleCamera}
                  handleTextClick={this.toggleCamera}
                  done={isCameraEnabled}
                  disabled={isRecordingStarting}
                />
              </div>
            </div>
          )}

          {isMeetingDone && (
            <div className={bem('finished-recording-preview-container')}>
              <div className={bem('finished-recording-preview')}>
                <video
                  controls
                  playsInline
                  src={viewUrl}
                  ref={this.previewVideoPlayerRef}
                />
              </div>
            </div>
          )}
        </div>

        <ConfirmationModal
          isOpen={showingConfirmStopModal}
          title={t('are_you_sure')}
          confirmAction={() => {
            this.stopRecording(true);
            this.setState({ showingConfirmStopModal: false });
          }}
          cancelAction={() => this.setState({ showingConfirmStopModal: false })}
          closeModal={() => this.setState({ showingConfirmStopModal: false })}
          text={t('confirm_finish')}
        />

        <InfoModal
          isOpen={showingInfoModal}
          title={t('recording_saved')}
          closeModal={() => this.setState({ showingInfoModal: false })}
          text={t('successfully_saved')}
        />

        <InfoModal
          isError
          isOpen={showingErrorModal}
          title={t('recording_error')}
          closeModal={() => { this.setState({ showingErrorModal: false }); }}
          text={
            errorModalMessage
            || <>{t('recording_error_occurred')}<br />${t('contact_support_error')}</>
          }
        />
      </div>
    );
  }
}

function LiveRecordingWrapper() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: RootState) => state.user.data);

  useEffect(() => {
    if (!currentUser?.id) {
      dispatch(fetchUser());
    }
  }, [dispatch, currentUser]);

  return <LiveRecording t={t} currentUser={currentUser} />
}

export default LiveRecordingWrapper;
