import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../store/store';
import { Appointment as AppointmentObj, AppointmentData } from '../../store/features/appointments/types';

import AppointmentComponent from '../../components/appointment/Appointment';

import { loadAppointments } from '../../store/features/appointments/appointmentsSlice';
import { fetchUser } from '../../store/features/user/userSlice';

interface AppointmentProps {
  searchTerm: string;
}

function Appointment({ searchTerm }: AppointmentProps) {
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: RootState) => state.user.data);

  const { data, status } = useSelector((state: RootState) => state.appointment);

  const accountId = user?.accounts[0].id;
  const userId = user?.id;

  useEffect(() => {
    if (accountId) {
      dispatch(loadAppointments({ accountId, filter: searchTerm, userId }));
    }
  }, [dispatch, userId, searchTerm, accountId]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const appointments = data?.map(
    (appointmentItem: AppointmentData) => new AppointmentObj(appointmentItem),
  );

  return (
    <AppointmentComponent
      appointments={appointments ?? null}
      status={status}
    />
  );
}

export default Appointment;
