import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppDispatch, RootState } from 'store/store';
import { setSearchTerm } from 'store/features/recordings/actions';
import { hideLogo } from 'store/features/user/userSlice';
import SearchBar from '../../form/SearchBar';
import MenuDropdown from '../../../../containers/MenuDropdown';
import { getAppNameFromPartner } from '../../../../store/features/partner/util/utilService';

import './header.css';

interface HeaderProps {
  partnerIcon?: string;
  isSearchShown: boolean;
  isMenuShown: boolean;
  onProfileClick: () => void;
  onSettingsClick: () => void;
  onPrivateMobileNumberClick: () => void;
}

function Header({
  partnerIcon,
  isSearchShown,
  isMenuShown,
  onProfileClick,
  onSettingsClick,
  onPrivateMobileNumberClick,
}: HeaderProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const searchTerm = useSelector((state: RootState) => state.recordings.searchTerm);

  const handleSearch = (search: string) => {
    dispatch(setSearchTerm(search));
  };

  const partner = useSelector((state: RootState) => state.partner.data);

  useEffect(() => {
    window.hideLogo = () => {
      dispatch(hideLogo());
    };
  });

  const logoHidden = useSelector((state: RootState) => state.user.logoHidden);

  const partnerAppName = partner ? getAppNameFromPartner(partner) : null;

  const appName = partnerAppName || t('header_title');

  return (
    <header className={`header-main ${isSearchShown ? '' : 'no-search'}`}>
      <div className="container header-container">
        <div className="top-row">
          {(partnerIcon && !logoHidden) ? (
            <img
              src={partnerIcon}
              alt="Partner icon"
              className="header__partner-icon"
            />
          ) : (
            <span />
          )}
          <h6 className="header__title">{appName}</h6>
          {isMenuShown ? (
            <MenuDropdown
              onProfileClick={onProfileClick}
              onSettingsClick={onSettingsClick}
              onPrivateMobileNumberClick={onPrivateMobileNumberClick}
            />
          ) : (
            <span />
          )}
        </div>
        {isSearchShown && <SearchBar value={searchTerm} onChange={handleSearch} />}
      </div>
    </header>
  );
}

Header.defaultProps = {
  partnerIcon: null,
};

export default Header;
