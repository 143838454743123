import { createSlice, Draft } from '@reduxjs/toolkit';

import { RecordingsState } from './types';
import { Status } from '../../../utils/constants';
import {
  RecordingUrlInfo,
  TranscriptDetails,
} from './models/types';
import Recording from './models/Recording';
import {
  loadFullRecordingDetails,
  loadRecordingTranscript,
  loadSwitchCallPrivacyType, setPageNumber,
  setScrollPosition,
  setSearchTerm,
} from './actions';
import { markTodoAsDone, markTodoAsUndone } from '../todos/todosSlice';
import { updatePaginatedData } from './util/utilService';

const PAGE_SIZE = 50;

const initialState: RecordingsState = {
  data: {},
  status: Status.Idle,
  todoStatus: Status.Idle,
  appointmentStatus: Status.Idle,
  transcriptStatus: Status.Idle,
  urlStatus: Status.Idle,
  pageNumber: 1,
  searchTerm: '',
  scrollPosition: 0,
  reachedEnd: false,
};

type Payload =
  | TranscriptDetails // return type of loadRecordingTranscript
  | RecordingUrlInfo // return type of loadRecordingUrl
  | null; // generic return type

const updateDataBySessionId = (
  recordingsState: Draft<RecordingsState>,
  sessionId: string,
  prop: keyof Recording,
  payload: Payload,
) => {
  const data = { ...recordingsState.data };

  if (sessionId in data) {
    data[sessionId] = {
      ...data[sessionId],
      [prop]: payload,
    };
  }

  recordingsState.data = data;
  recordingsState.status = Status.Succeeded;
};

export const recordingsSlice = createSlice({
  name: 'recordings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadFullRecordingDetails.fulfilled, (recordingsState, action) => {
        const { arg } = action.meta;
        if (
          Object.keys(recordingsState.data).length <= 1
          || (
            (!arg?.page || arg.page === 1)
            && arg?.searchTerm
          )
        ) {
          recordingsState.data = {};
        }
        updatePaginatedData(recordingsState, action.payload.recordings);
        recordingsState.appointmentStatus = Status.Succeeded;
        recordingsState.todoStatus = Status.Succeeded;
        recordingsState.status = Status.Succeeded;
        recordingsState.reachedEnd = action.payload.recordings.length < PAGE_SIZE;
      })
      .addCase(loadFullRecordingDetails.pending, (recordingsState) => {
        recordingsState.status = Status.Loading;
      })
      .addCase(loadRecordingTranscript.pending, (recordingsState) => {
        recordingsState.transcriptStatus = Status.Loading;
      })
      .addCase(
        loadRecordingTranscript.fulfilled,
        (recordingsState, action) => {
          const { sessionId } = action.meta.arg;
          updateDataBySessionId(recordingsState, sessionId, 'transcriptDetails', action.payload ?? null);

          recordingsState.transcriptStatus = Status.Succeeded;
        },
      )
      .addCase(loadRecordingTranscript.rejected, (recordingsState) => {
        recordingsState.transcriptStatus = Status.Failed;
      })
      .addCase(markTodoAsDone.fulfilled, (recordingsState, action) => {
        const todoObj = action.payload;

        const recording = recordingsState.data[todoObj.session_id];
        if (recording) {
          const todoIndex = recording.todos.findIndex((todo) => todo.id === todoObj.id);
          if (todoIndex !== -1) {
            recording.todos[todoIndex].completed = true;
          } else {
            recording.todos.push(todoObj);
          }
        }
      })
      .addCase(markTodoAsUndone.fulfilled, (recordingsState, action) => {
        const todoObj = action.payload;

        const recording = recordingsState.data[todoObj.session_id];
        if (recording) {
          const todoIndex = recording.todos.findIndex((todo) => todo.id === todoObj.id);
          if (todoIndex !== -1) {
            recording.todos[todoIndex].completed = false;
          }
        }
      })
      .addCase(loadSwitchCallPrivacyType.fulfilled, (recordingsState, action) => {
        recordingsState.appointmentStatus = Status.Succeeded;

        const { sessionId } = action.meta.arg;
        const recording = recordingsState.data[sessionId];

        if (recording) {
          recording.info.private = false;
        }
      })
      .addCase(setPageNumber.fulfilled, (recordingsState, action) => {
        recordingsState.pageNumber = action.payload.pageNumber;
      })
      .addCase(setSearchTerm.fulfilled, (recordingsState, action) => {
        recordingsState.searchTerm = action.payload;
        recordingsState.pageNumber = 1;
      })
      .addCase(setScrollPosition.fulfilled, (recordingsState, action) => {
        recordingsState.scrollPosition = action.payload;
      });
  },
});

export default recordingsSlice.reducer;
