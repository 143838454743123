import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadFullRecordingDetails, loadRecordingTranscript, loadRecordingUrl } from 'store/features/recordings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchUser } from 'store/features/user/userSlice';
import RecordingDetail from '../containers/RecordingDetail';

interface RecordingDetailPageProps {
  sessionId?: string;
}

function RecordingDetailPage({
  sessionId,
}: RecordingDetailPageProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [startTime, setStartTime] = useState<number | undefined>();

  const [fetchingUser, setFetchingUser] = useState(false);
  const [fetchingRecording, setFetchingRecording] = useState(false);
  const [fetchingTranscript, setFetchingTranscript] = useState(false);

  const recording = useSelector(
    (state: RootState) => (sessionId ? state.recordings.data[sessionId] : null),
  );
  const accountId = useSelector(
    (state: RootState) => state.user.data?.accounts[0].id,
  );

  useEffect(() => {
    if (!accountId && !fetchingUser) {
      dispatch(fetchUser());
      setFetchingUser(true);
    }

    if (sessionId && accountId && !recording && !fetchingRecording) {
      dispatch(loadFullRecordingDetails({ sessionId }));
      setFetchingRecording(true);
    }

    if (sessionId && recording && !fetchingTranscript) {
      dispatch(loadRecordingTranscript({ sessionId }));
      setFetchingTranscript(true);
    }
  }, [sessionId, accountId, recording]);

  const handleTodoClick = (todo: any) => {

  };

  const handleAppointmentClick = (appointment: any) => {

  };

  const closeModal = () => {
    navigate(-1);
  };

  return recording && (
    <RecordingDetail
      recording={recording}
      closeModal={() => closeModal()}
      startTime={startTime}
      onTodoClick={handleTodoClick}
      onAppointmentClick={handleAppointmentClick}
    />
  );
}

RecordingDetailPage.defaultProps = {
  sessionId: undefined,
};

export default RecordingDetailPage;
