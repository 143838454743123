import React, { useState } from 'react';

import Conversation from '../containers/Conversation';

function Conversations() {
  return (
    <Conversation />
  );
}

export default Conversations;
