import React from 'react';
import { useTranslation } from 'react-i18next';

import './recording-transcript.css';

import { TranscriptInfo } from '../../../store/features/recordings/models/types';
import { PersonData } from '../../../store/features/recordings/data/PersonData';

interface RecordingTruncatedTranscriptProps {
  transcript: TranscriptInfo;
  person: PersonData;
  className?: string;
}

function RecordingTruncatedTranscript({
  transcript,
  person,
  className,
}: RecordingTruncatedTranscriptProps) {
  const { t } = useTranslation();

  return (
    <div className={`recording-truncated-transcript__details ${className || ''}`}>
      <p className="recording-truncated-transcript__speaker">
        {(
          transcript.speakerId !== person.id
            ? [transcript.speakerFirstName, transcript.speakerLastName].join(' ').trim()
            : person.name
        ) || t('unknown_person')}
        :
      </p>
      <p className="recording-truncated-transcript__sentence">
        {transcript.transcript}
      </p>
    </div>
  );
}

RecordingTruncatedTranscript.defaultProps = {
  className: null,
};

export default RecordingTruncatedTranscript;
