import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppDispatch, RootState } from '../../store/store';
import ConversationComponent from '../../components/conversation/Conversation';
import { UserData } from '../../store/features/user/types';
import { Status } from '../../utils/constants';
import Recording from '../../store/features/recordings/models/Recording';
import { fetchUser } from '../../store/features/user/userSlice';

function Conversation() {
  const [hasFetchedRecording, setHasFetchedRecording] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: RootState) => state.user.data);

  const {
    data: recordingsData,
    status: recordingStatus,
  } = useSelector(
    (state: RootState) => state.recordings,
  );

  const { status: todoStatus } = useSelector(
    (state: RootState) => state.todos,
  );

  const { status: appointmentStatus } = useSelector(
    (state: RootState) => state.appointment,
  );

  const urlSearchParams = new URLSearchParams(location.search);
  const sessionId = urlSearchParams.get('session_id') ?? undefined;

  useEffect(() => {
    if (!user?.id) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  let recordingFromURI: Recording | null = null;

  if (
    sessionId
    && [recordingStatus, todoStatus, appointmentStatus].every(
      (status) => status === Status.Succeeded,
    )
    && Object.keys(recordingsData).length > 0
    && recordingsData[sessionId]
  ) {
    recordingFromURI = recordingsData[sessionId];
  }

  return (
    <ConversationComponent
      conversations={recordingsData}
      recordingStatus={recordingStatus}
      recordingFromURI={recordingFromURI}
    />
  );
}

export default Conversation;
