import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter, useParams,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

import './index.css';
import './i18nConfig';
import LiveRecording from './containers/LiveRecording';
import PreloadWrapper from './containers/Preload';
import { RootState, store } from './store/store';
import Login from './containers/Login';
import AuthErrorMsg from './components/ui/auth-error-msg';
import { authorized } from './store/features/user/userSlice';
import useTheme from './store/features/partner/hooks/useTheme';
import usePartnerIcon from './store/features/partner/hooks/usePartnerIcon';
import usePartnerLoginToken from './store/features/user/hooks/usePartnerLoginToken';
import { msAuthErrorPath } from './utils/msTeams';

import Conversations from './pages/Conversations';
import Todos from './pages/Todos';
import Appointments from './pages/Appointments';
import Navigation from './components/navigation/Navigation';
import RecordingDetailPage from './pages/RecordingDetailPage';

Sentry.init({
  dsn: 'https://cf09702784eba31cd29890957327da83@o4508331072487424.ingest.de.sentry.io/4508332477907024',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api.(meetric|acceptstack|acceptflow).com/],

  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a
  // lower rate in production.
  replaysSessionSampleRate: 0.1,

  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions
  // where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  const originalWarn = console.error;
  // eslint-disable-next-line no-console
  console.error = (...args) => {
    if (
      args[0]?.includes?.(
        'Support for defaultProps will be removed from function components in a future major release.',
      )
    ) {
      return;
    }
    originalWarn(...args);
  };
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const loader = document.getElementById('initial-loader');
loader?.remove();

interface ProtectedRouteProps {
  children: React.ReactElement;
}

interface RedirectIfAuthorizedRouteProps {
  children: React.ReactElement;
}
declare global {
  interface Window {
    hideLogo: any;
    inTeams: boolean;
  }

  interface Element {
    style: any;
  }
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  if (!authorized()) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function RedirectIfAuthorized({ children }: RedirectIfAuthorizedRouteProps) {
  if (authorized()) {
    return <Navigate to="/conversations" replace />;
  }

  return children;
}

function RecordingDetailPageWrapper() {
  const { sessionId } = useParams();
  return (
    <RecordingDetailPage
      sessionId={sessionId}
    />
  );
}

function App() {
  const { i18n, t } = useTranslation();

  const partner = useSelector((state: RootState) => state.partner.data);

  useTheme(partner?.theme);
  usePartnerIcon(partner?.logoFavicon);
  usePartnerLoginToken();

  useEffect(() => {
    document.title = t('app_name');
  }, [i18n.language]);

  return (
    <Routes>
      <Route
        path="/login"
        element={(
          <RedirectIfAuthorized>
            <Login />
          </RedirectIfAuthorized>
        )}
      />
      <Route
        path="*"
        element={(
          <ProtectedRoute>
            <Navigation
              partnerIcon={partner?.logoFavicon}
            >
              <Routes>
                <Route path="/" element={<Navigate to="/conversations" />} />
                <Route path="/conversations" element={<Conversations />} />
                <Route path="/todos" element={<Todos />} />
                <Route path="/appointments" element={<Appointments />} />
                <Route path="/live-recording" element={<LiveRecording />} />
              </Routes>
            </Navigation>
          </ProtectedRoute>
        )}
      />
      <Route path="/logout" element={<Navigate to="/login" replace />} />
      <Route
        path={msAuthErrorPath}
        element={(
          <AuthErrorMsg />
        )}
      />
      <Route
        path="/conversations/:sessionId"
        element={
          <RecordingDetailPageWrapper />
        }
      />
    </Routes>
  );
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PreloadWrapper>
          <FluentProvider theme={teamsLightTheme}>
            <App />
          </FluentProvider>
        </PreloadWrapper>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
