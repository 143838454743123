import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './info-modal.css';
import Modal from '../modal';

interface InfoModalProps {
  isOpen: boolean;
  text: string | ReactNode;
  title: string;
  buttonText?: string;
  closeModal?: () => void;
  isError?: boolean;
}

function InfoModal({
  isOpen,
  text,
  title,
  buttonText = '',
  closeModal = () => {},
  isError = false,
}: InfoModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} className={`info-modal ${isError ? 'error-modal' : ''}`}>
      <div className="info-modal-header">
        <div className="info-modal-header__title">{title}</div>
      </div>
      <div className="text-align">{text}</div>
      <div className="info-modal-footer">
        <button
          type="button"
          className={`confirm-button ${isError ? 'secondary-button' : ''}`}
          onClick={closeModal}
        >
          {buttonText || t('OK')}
        </button>
      </div>
    </Modal>
  );
}

InfoModal.defaultProps = {
  buttonText: '',
  closeModal: () => {},
  isError: false,
};

export default InfoModal;
