import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchRecordingBySession,
  fetchConversations,
  fetchRecordingTranscript,
  fetchTodosBySession,
  switchCallPrivacyType,
} from './recordingsAPI';
import { RecordingsState } from './types';
import Recording from './models/Recording';

export const loadFullRecordingDetails = createAsyncThunk(
  'recordings/loadFullRecordingDetails',
  async ({
    sessionId = null,
    page = 1,
    searchTerm = '',
  }: {
    sessionId?: string | null;
    page?: number;
    searchTerm?: string;
  } = {}) => {
    const recordings = await fetchConversations({ page, sessionId, searchTerm });
    const appointments = recordings.flatMap((recording) => recording.appointments);
    const todos = recordings.flatMap((recording) => recording.todos);
    return {
      recordings,
      appointments,
      todos,
    };
  },
);

export const loadRecordingTranscript = createAsyncThunk(
  'recordings/loadRecordingTranscript',
  async ({ sessionId }: { sessionId: string }) => {
    const response = await fetchRecordingTranscript(sessionId);

    return response;
  },
);

export const loadRecordingTodos = createAsyncThunk(
  'recordings/loadRecordingTodos',
  async ({ accountId, sessionId }: { accountId: string; sessionId: string }) => {
    const response = await fetchTodosBySession(accountId, sessionId);

    return response;
  },
);

export const loadRecordingUrl = createAsyncThunk(
  'recordings/loadRecordingUrl',
  async ({ sessionId }: { sessionId: string }) => {
    const response = await fetchRecordingBySession(sessionId);

    return response;
  },
);

export const loadSwitchCallPrivacyType = createAsyncThunk(
  'recordings/loadSwitchCallPrivacyType',
  async ({ sessionId }: { sessionId: string; }) => {
    await switchCallPrivacyType(sessionId);
  },
);

export const getRecordingBySessionId = (
  state: RecordingsState,
  sessionId: string,
): Recording | undefined => state.data[sessionId];

// TODO these are not async, just use a normal action

export const setPageNumber = createAsyncThunk(
  'recordings/setPageNumber',
  async ({ pageNumber }: { pageNumber: number }) => ({ pageNumber }),
);

export const setSearchTerm = createAsyncThunk(
  'recordings/setSearchTerm',
  async (searchTerm: string) => searchTerm,
);

export const setScrollPosition = createAsyncThunk(
  'recordings/setScrollPosition',
  async (scrollPosition: number) => scrollPosition,
);
