/* eslint-disable */

import React from 'react';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../components/ui/button';
import Input from '../../components/ui/input';

interface EditParticipantsListProps {
  participants: any[];
  onUpdate: (participants: any[]) => void;
  disabled?: boolean;
  disabledParticipantIds: any[];
  t: (label: string) => string;
}

export const validateEmail = (email: string) => `${email}`
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export function createParticipant() {
  return {
    tempId: uuidv4(),
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };
}

export class EditParticipantsList extends React.Component<EditParticipantsListProps> {

  updateParticipantField = (index: number, field: string, value: any) => {
    const updatedParticipants = produce(this.props.participants, draftParticipants => {
      draftParticipants[index][field] = value;

      if (field === 'email') {
        const { firstName, lastName } = this.deriveNamesFromEmail(value);
        draftParticipants[index].firstName = firstName;
        draftParticipants[index].lastName = lastName;
      }
    });
    this.props.onUpdate(updatedParticipants);
  };

  addParticipant = () => {
    const updatedParticipants = [
      ...this.props.participants,
      createParticipant(),
    ];
    this.props.onUpdate(updatedParticipants);
  };

  removeParticipant = (index: number) => {
    const updatedParticipants = produce(this.props.participants, draftParticipants => {
      draftParticipants.splice(index, 1);
    });
    this.props.onUpdate(updatedParticipants);
  };

  deriveNamesFromEmail = (email: string) => {
    const [localPart] = email.split('@');
    const [firstName, lastName] = localPart.split('.');

    return {
      firstName: firstName ? firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase() : '',
      lastName: lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase() : ''
    };
  }

  render() {
    const { t } = this.props;

    return <div className="participants-list">
      {this.props.participants.map((participant, index) => (
        <div className="participant-data" key={participant.id || participant.tempId}>
          <Input
            type="email"
            className={`email ${(participant.email && !validateEmail(participant.email)) ? 'invalid' : ''}`}
            placeholder={t('email')}
            disabled={this.props.disabled || participant.id}
            value={participant.email}
            onChange={(e: any) => this.updateParticipantField(index, 'email', e.target.value)}
            isRequired={false}
          />
          <Input
            type="text"
            className="first-name"
            placeholder={t('first-name')}
            disabled={this.props.disabled || this.props.disabledParticipantIds.includes(participant.id)}
            value={participant.firstName}
            onChange={(e: any) => this.updateParticipantField(index, 'firstName', e.target.value)}
            isRequired={false}
          />
          <Input
            type="text"
            className="last-name"
            placeholder={t('last-name')}
            disabled={this.props.disabled || this.props.disabledParticipantIds.includes(participant.id)}
            value={participant.lastName}
            onChange={(e: any) => this.updateParticipantField(index, 'lastName', e.target.value)}
            isRequired={false}
          />
          <Input
            type="tel"
            className="phone"
            placeholder={t('mobile-number')}
            disabled={this.props.disabled || this.props.disabledParticipantIds.includes(participant.id)}
            value={participant.phone}
            onChange={(e: any) => this.updateParticipantField(index, 'phone', e.target.value)}
            isRequired={false}
          />
          <Button
            type="button"
            className="button remove-participant secondary-button"
            disabled={this.props.disabled}
            description={t('remove')}
            handleClick={() => this.removeParticipant(index)}
          />
          {(index === this.props.participants.length - 1) && (
            <Button
            type="button"
            className="button add-participant"
            disabled={this.props.disabled}
            handleClick={this.addParticipant}
            description={`＋ ${t('add')}`}
            />
          )}
        </div>
      ))}
      {(this.props.participants.length === 0) && (
        <>
          <span>({t('no_participants')})</span>
          <Button
            type="button"
            className="button add-participant add-participant-text"
            disabled={this.props.disabled}
            handleClick={this.addParticipant}
            description={`＋ ${t('add_participant')}`}
          />
        </>
      )}
    </div>
  }
}
