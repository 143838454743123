/* eslint-disable max-len */
import React from 'react';

function User({ fill }: { fill: string }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0C2.68548 0 0 2.68548 0 6C0 9.31452 2.68548 12 6 12C9.31452 12 12 9.31452 12 6C12 2.68548 9.31452 0 6 0ZM6 2.32258C7.17581 2.32258 8.12903 3.27581 8.12903 4.45161C8.12903 5.62742 7.17581 6.58065 6 6.58065C4.82419 6.58065 3.87097 5.62742 3.87097 4.45161C3.87097 3.27581 4.82419 2.32258 6 2.32258ZM6 10.6452C4.57984 10.6452 3.30726 10.0016 2.45565 8.99516C2.91048 8.13871 3.80081 7.54839 4.83871 7.54839C4.89677 7.54839 4.95484 7.55806 5.01048 7.575C5.325 7.67661 5.65403 7.74194 6 7.74194C6.34597 7.74194 6.67742 7.67661 6.98952 7.575C7.04516 7.55806 7.10323 7.54839 7.16129 7.54839C8.19919 7.54839 9.08952 8.13871 9.54435 8.99516C8.69274 10.0016 7.42016 10.6452 6 10.6452Z"
        fill={fill}
      />
    </svg>
  );
}

export default User;
