import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppDispatch } from '../../store/store';
import { login } from '../../store/features/user/userSlice';
import LoginComponent from '../../components/login/Login';
import { resetPassword } from '../../store/features/user/userAPI';
import PasswordResetForm from '../../components/login/PasswordResetForm';

function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [resetStatus, setResetStatus] = useState({
    success: false,
    message: '',
  });
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [emailForReset, setEmailForReset] = useState('');
  const { t } = useTranslation();

  const handleLogin = async (username: string, password: string) => {
    try {
      const actionResult = await dispatch(login({ username, password }));
      if (login.fulfilled.match(actionResult)) {
        navigate('/conversations');
      } else {
        console.log('Login failed');
      }
    } catch (error) {
      console.error('Failed to login:', error);
    }
  };

  const handlePasswordForgot = async () => {
    try {
      const response = await resetPassword(emailForReset);
      if (response.status === 200) {
        setResetStatus({
          success: true,
          message: t('reset_password_success_message'),
        });
      } else {
        setResetStatus({ success: false, message: t('message_something_went_wrong') });
      }
    } catch (error) {
      setResetStatus({ success: false, message: t('message_something_went_wrong') });
    }
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
  };

  const clearResetStatus = () => {
    setResetStatus({ success: false, message: '' });
  };

  const resetForgotPasswordStates = () => {
    setIsForgotPassword(false);
    setEmailForReset('');
    clearResetStatus();
  };

  if (isForgotPassword) {
    return (
      <PasswordResetForm
        resetStatus={resetStatus}
        emailForReset={emailForReset}
        setEmailForReset={setEmailForReset}
        onSubmit={handlePasswordForgot}
        onBackToLogin={resetForgotPasswordStates}
        clearResetStatus={clearResetStatus}
        setResetStatus={setResetStatus}
      />
    );
  }

  return (
    <LoginComponent
      onLogin={handleLogin}
      onPasswordForgot={toggleForgotPassword}
    />
  );
}

export default Login;
