// eslint-disable-next-line import/prefer-default-export
import { Partner } from '../data';

export const extractHost = (url: string | undefined) => {
  try {
    if (!url) {
      return null;
    }
    const parsedUrl = new URL(url);
    return parsedUrl.host;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

export const getAppNameFromPartner = (partner: Partner) => {
  if (partner.appNameSwitchboard && partner.appNameSwitchboard !== '') {
    return partner.appNameSwitchboard;
  }

  if (partner.appNameDashboard && partner.appNameDashboard !== '') {
    return partner.appNameDashboard;
  }

  return null;
};
