import React from 'react';

import './record.css';

function Record({ width, height }: { width: string, height: string }) {
  return (
    <svg
      width={width}
      height={height}
      className="record"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="color" />
      <g id="line">
        <circle
          cx="12"
          cy="12"
          r="9"
          fill="none"
          stroke="#FFFFFF"
          strokeLinejoin="round"
          strokeMiterlimit="1"
          strokeWidth="1"
        />
        <circle cx="12" cy="12" r="5" fill="#FFFFFF" stroke="none" />
      </g>
    </svg>
  );
}

export default Record;
