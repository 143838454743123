import axios from 'axios';
import setupTeamsIfPresent from './msTeams';

export const acceptFlowURL = 'https://switchboard.acceptflow.com';
export const acceptStackURL = 'https://switchboard.acceptstack.com';
export const devioneStagingURL = 'https://switch.devioneprojects.com';
export const meetricURL = 'https://switchboard.meetric.com';
export const devioneProdURL = 'https://switch.devione.com';
export const localhostURL = 'http://localhost:3000';

export const developApiURL = 'https://api.acceptflow.com/api';
export const stagingApiURL = 'https://api.acceptstack.com/api';
export const prodApiURL = 'https://api.meetric.com/api';
export const localApiURL = 'http://localhost:8000/api';

export const baseURL = () => {
  switch (window.location.origin) {
    case acceptFlowURL:
      return developApiURL;
    case acceptStackURL:
    case devioneStagingURL:
      return stagingApiURL;
    case localhostURL:
      return stagingApiURL;
    default:
      return process.env.REACT_APP_API_BASE_URL ?? prodApiURL;
  }
};

export const getDefaultAppUrl = () => {
  switch (process.env.REACT_APP_API_BASE_URL) {
    case developApiURL:
      return acceptFlowURL;
    case stagingApiURL:
      return acceptStackURL;
    case localApiURL:
      return localhostURL;
    default:
      return meetricURL;
  }
};

export const isLocalhostHost = () => window.location.origin.includes('localhost');

const axiosInstance = axios.create({
  baseURL: `${baseURL()}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*',
  },
});

function getCookie(name: string) {
  if (!document.cookie) {
    return null;
  }

  const cookieName = `${name}=`;
  const cookieArray = document.cookie.split(';');

  let foundCookieValue: string | null = null;
  cookieArray.forEach((cookie) => {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.indexOf(cookieName) === 0 && foundCookieValue === null) {
      foundCookieValue = decodeURIComponent(
        trimmedCookie.substring(cookieName.length),
      );
    }
  });

  return foundCookieValue;
}

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getCookie('JWT_Token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

setupTeamsIfPresent(axiosInstance);

export default axiosInstance;
