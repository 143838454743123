import React, { useState } from 'react';

import Todo from '../containers/Todo';

function Todos() {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Todo searchTerm={searchTerm} />
  );
}

export default Todos;
