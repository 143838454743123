import { Draft } from '@reduxjs/toolkit';
import { mapToInfoType } from '../../../../utils/utils';
import { RecordingsState } from '../types';
import { Info } from '../models/types';

export const formatSummaryDefault = (summary: string) => {
  const formattedSummary = summary.replace(
    /^(.*?)(: |: |- )/gm,
    '<span style="font-weight: bold">$1</span>$2',
  );

  // Split the summary into points based on newlines
  return formattedSummary.split(/\n+/gm).filter(Boolean);
};

export const formatSummaryWithAsterisks = (summary: string) => {
  // Split the summary into lines
  const lines = summary.split('\n');
  const processedLines: string[] = [];

  lines.forEach((line: string) => {
    let newLine = line;
    // Count pairs of asterisks (**)
    const asteriskPairCount = (line.match(/\*\*/g) || []).length;

    // If the count of pairs is odd, wrap the line with additional asterisks
    if (asteriskPairCount % 2 !== 0) {
      newLine = newLine.replace(/\*/g, ''); // Remove all asterisks in the line
      newLine = `**${newLine}**`; // Add a pair of asterisks at the start and end
    }

    // Replace markdown bold notation with <span>
    newLine = newLine.replace(
      /\*\*(.*?)\*\*/g,
      '<span style="font-weight: bold">$1</span>',
    );

    // Push the processed line to the results array
    processedLines.push(newLine);
  });

  // Join the processed lines back into a single formatted string
  return processedLines.filter(Boolean);
};

export const updatePaginatedData = (
  recordingsState: Draft<RecordingsState>,
  recordings: Info[],
) => {
  recordings.forEach((item: any) => {
    const info: Info = mapToInfoType(item);
    recordingsState.data[info.session_id] = {
      sessionId: info.session_id,
      info,
      transcriptDetails: null,
      todos: info.todos.map((todo) => ({ ...todo, session_id: info.session_id })),
      appointments: info.appointments.map((appointment) => ({ ...appointment, session_id: info.session_id })),
      urlInfo: {
        language: info.language,
        screenshots: info.screenshots,
        tags: info.tags,
        url: info.url,
      },
    };
  });
};

export class SummaryData {
  data: {
    summary: {
      session_id: string;
      summary: string;
      summary_dashboard: string | null;
      summary_switchboard: string | null;
      seller_questions: string[];
      buyer_questions: string[];
      objections: string[];
      deal_probability_reason: string;
      deal_probability_percent: number | null;
    };
  };

  constructor(
    session_id: string,
    summary: string,
    seller_questions: string[],
    buyer_questions: string[],
    objections: string[],
    deal_probability_reason: string,
    deal_probability_percent: number | null,
    summary_switchboard: string | null,
    summary_dashboard: string | null,
  ) {
    this.data = {
      summary: {
        session_id,
        summary,
        seller_questions,
        buyer_questions,
        objections,
        deal_probability_reason,
        deal_probability_percent,
        summary_dashboard,
        summary_switchboard,
      },
    };
  }

  formatSummary = () => {
    const summary = this.data.summary.summary_switchboard !== null
      && this.data.summary.summary_switchboard !== undefined
      ? this.data.summary.summary_switchboard
      : '';

    if (/\*\*/.test(summary)) {
      return formatSummaryWithAsterisks(summary);
    }

    // Call the static method
    return formatSummaryDefault(summary);
  };
}
