import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import './recording-transcript.css';
import LoaderDashed from '../../ui/icons/LoaderDashed/LoaderDashed';
import { TranscriptInfo } from '../../../store/features/recordings/models/types';
import { PersonData } from '../../../store/features/recordings/data/PersonData';

interface RecordingTranscriptProps {
  transcript: TranscriptInfo;
  onSeekToTime: (time: number) => void;
  person: PersonData;
  className?: string;
  showIcon?: boolean;
}

export const formatTime = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  if (hours > 0) {
    minutes %= 60;
  }
  const remainingSeconds = Math.floor(seconds % 60);
  const formattedHours = hours > 0 ? `${hours}:` : '';
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  const formatted = `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
  return formatted;
};

const RecordingTranscript = forwardRef<
HTMLDivElement,
RecordingTranscriptProps
>(({
  transcript,
  onSeekToTime,
  className,
  person,
  showIcon,
}, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className={`recording-transcript__details ${className || ''}`}>
      <div className="recording-transcript__loader-dashed">
        {showIcon && <LoaderDashed />}
      </div>
      <span>
        <span
          className="recording-transcript__time"
          onClick={() => onSeekToTime(transcript.start)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onSeekToTime(transcript.start);
            }
          }}
          role="button"
          tabIndex={0}
        >
          {formatTime(transcript.start)}
          {' '}
          <span
            className="recording-transcript__speaker"
            onClick={() => onSeekToTime(transcript.start)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onSeekToTime(transcript.start);
              }
            }}
            role="button"
            tabIndex={0}
          >
            {(
              transcript.speakerId !== person.id
                ? [transcript.speakerFirstName, transcript.speakerLastName].join(' ').trim()
                : person.name
            ) || t('unknown_person')}
            :
          </span>
        </span>
      </span>

      <p className="recording-transcript__sentence">{transcript.transcript}</p>
    </div>
  );
});

RecordingTranscript.defaultProps = {
  className: '',
  showIcon: false,
};

RecordingTranscript.displayName = 'RecordingTranscript';

export default RecordingTranscript;
