import React, { useState } from 'react';

import Appointment from '../containers/Appointment';

function Appointments() {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Appointment searchTerm={searchTerm} />
  );
}

export default Appointments;
