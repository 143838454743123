import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';

import './navigation.css';

import Header from '../../ui/header/Header';
import NavBar from '../../ui/navbar/NavBar';

import Profile from '../../../containers/Profile';
import Settings from '../../../containers/Settings';
import PrivateMobileNumber from '../../../containers/PrivateMobileNumber';

import Chat from '../../ui/icons/Chat/Chat';
import TodoIcon from '../../ui/icons/Todo/Todo';
import Calendar from '../../ui/icons/Calendar/Calendar';
import Record from '../../ui/icons/Record/Record';
import {
  msHostNameKey,
  msTeamsTokenKey,
  getMsTeamsAuthToken,
  getMsTeamsContext,
} from '../../../utils/msTeams';

interface TabData {
  id: string;
  label: string;
  icon: React.ReactNode;
  path: string;
}

interface NavigationProps {
  partnerIcon?: string;
  children: React.ReactNode;
}

const tabs: TabData[] = [
  {
    id: 'conversations', label: 'Conversation', icon: <Chat />, path: '/conversations',
  },
  {
    id: 'todos', label: 'Todos', icon: <TodoIcon />, path: '/todos',
  },
  {
    id: 'appointments', label: 'Appointments', icon: <Calendar width="37px" height="37px" />, path: '/appointments',
  },
  {
    id: 'live', label: 'Live Recording', icon: <Record width="37px" height="37px" />, path: '/live-recording',
  },
];

function Navigation({
  partnerIcon,
  children,
}: NavigationProps) {
  const [activeTab, setActiveTab] = useState(0);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isPrivateMobileNumberOpen, setIsPrivateMobileNumberOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const tabIndex = tabs.findIndex((tab) => tab.path === currentPath);
    if (tabIndex !== -1) {
      setActiveTab(tabIndex);
    }
  }, [location.pathname, tabs]);

  const liveIndex = tabs.findIndex((tab) => tab.id === 'live');

  const isLiveInIframe = (index: number) => index === liveIndex && window.top !== window;

  const isPopupFromTeams = window.inTeams && window.top === window;

  const openLiveInPopup = async () => {
    microsoftTeams.initialize();
    let query = '';
    const msContext: any = await getMsTeamsContext();
    if (msContext) {
      query += `?${msHostNameKey}=${msContext.hostName}&${msTeamsTokenKey}=${
        await getMsTeamsAuthToken()
      }`;
    }
    window.open(tabs[liveIndex].path + query, '_blank', 'popup=yes');
  };

  const handleTabChange = (index: number) => {
    if (isLiveInIframe(index)) {
      try {
        openLiveInPopup();
        return;
      } catch (e: any) {
        // TODO: show a generic error modal
        console.error(e);
        throw e;
      }
    }

    const selectedTab = tabs[index];
    if (selectedTab && selectedTab.path) {
      navigate(selectedTab.path);
    }
  };

  return (
    <main>
      <Header
        partnerIcon={partnerIcon}
        isSearchShown={activeTab !== liveIndex}
        isMenuShown={!isPopupFromTeams}
        onSettingsClick={() => setIsSettingsOpen(true)}
        onProfileClick={() => setIsProfileOpen(true)}
        onPrivateMobileNumberClick={() => setIsPrivateMobileNumberOpen(true)}
      />
      <div id="scrollableDiv" className="container scrollable">
        <div className="list-container">
          {children}
        </div>
      </div>
      {isSettingsOpen && (
        <Settings onClose={() => setIsSettingsOpen(false)} />
      )}

      {isProfileOpen && (
        <Profile onClose={() => setIsProfileOpen(false)} />
      )}

      {isPrivateMobileNumberOpen && (
        <PrivateMobileNumber onClose={() => setIsPrivateMobileNumberOpen(false)} />
      )}

      {!isPopupFromTeams && (
        <NavBar activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange} />
      )}
    </main>
  );
}

Navigation.defaultProps = {
  partnerIcon: null,
};

export default Navigation;
