/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */

const sleep = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

const FIVE_MB = 5 * 1024 * 1024;
const URLS_PAGE = 50;
export const firstPartFailedError = new Error('First part of recording failed to upload');

export async function retry(asyncFunc, { retryTimes = 10, maxWaitSeconds = Infinity } = {}) {
  let attemptNum = 1;
  while (attemptNum < Infinity) {
    try {
      const returnValue = await asyncFunc();
      return returnValue;
    } catch (e) {
      if (++attemptNum > retryTimes) throw e;
      await sleep(Math.min(maxWaitSeconds, attemptNum * attemptNum) * 1000);
    }
  }
  return Promise.reject();
}

export async function streamToS3Urls(mediaRecorder, initialUrls, getMoreUrls) {
  let currentUrlIndex = 0;
  const partPromises = [];
  const urls = initialUrls;

  let onRecordingEnded;
  const recordingEnded = new Promise((resolve) => { onRecordingEnded = resolve; });
  const threeMinutesAfterRecordingEnded = recordingEnded.then(() => sleep(180000));

  let urlsAvailable = urls.length;

  // TODO: if uploading to undefined urls those parts are lost...
  const appendUrlsIfNeeded = async () => {
    if (urls.length - currentUrlIndex >= URLS_PAGE / 2) {
      return;
    }
    const urlsAvailableAtStart = urlsAvailable;
    const { data: newUrls } = await getMoreUrls(
      urlsAvailable + 1,
      Math.max(
        URLS_PAGE,
        URLS_PAGE + (currentUrlIndex - urlsAvailable) * 4,
      ),
    );
    newUrls.forEach((url, i) => { urls[urlsAvailableAtStart + i] = url; });
    urlsAvailable = Math.max(urlsAvailable, urlsAvailableAtStart + newUrls.length);
  };

  const sendPart = (body) => {
    const partIndex = currentUrlIndex++;
    const fetchFunc = () => fetch(urls[partIndex], {
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      body,
    });
    partPromises[partIndex] = partIndex > 0
      ? retry(fetchFunc)
      : Promise.race([
        retry(fetchFunc, { retryTimes: Infinity, maxWaitSeconds: 30 }),
        threeMinutesAfterRecordingEnded.then(() => Promise.reject(firstPartFailedError)),
      ]);
    retry(appendUrlsIfNeeded);
    return partPromises[partIndex];
  };

  try {
    // eslint-disable-next-line no-new
    new ReadableStream({ type: 'bytes' });
  } catch (e) {
    console.debug('Binary stream not supported');
    let blobChunks = [];
    let totalSize = 0;

    mediaRecorder.ondataavailable = (event) => {
      blobChunks.push(event.data);
      totalSize += event.data.size;

      if (totalSize >= FIVE_MB) {
        sendPart(new Blob(blobChunks, { type: blobChunks[0].type }));
        blobChunks = [];
        totalSize = 0;
      }
    };

    await new Promise((resolve, reject) => {
      mediaRecorder.onstop = () => {
        if (totalSize > 0) {
          sendPart(new Blob(blobChunks, { type: blobChunks[0].type }));
        }
        onRecordingEnded();
        resolve();
      };

      mediaRecorder.onerror = (err) => {
        onRecordingEnded();
        reject(err);
      };

      mediaRecorder.start(1000);
    });

    const responses = await Promise.all(partPromises);
    return responses.map((response) => response.headers.get('ETag').replace(/"/g, ''));
  }

  console.debug('Binary stream supported');
  const binaryStream = new ReadableStream({
    start: (controller) => {
      mediaRecorder.ondataavailable = async (event) => {
        controller.enqueue(new Uint8Array(await event.data.arrayBuffer()));
      };

      mediaRecorder.onstop = () => {
        controller.close();
      };

      mediaRecorder.start(1000);
    },
    type: 'bytes',
  });

  let buffer = new ArrayBuffer(FIVE_MB);
  let dataArray = new Uint8Array(buffer);
  let byteOffset = 0;

  return new Promise((resolve, reject) => {
    const writableStream = new WritableStream({
      write(chunk) {
        if (byteOffset + chunk.length <= FIVE_MB) {
          dataArray.set(chunk, byteOffset);
          byteOffset += chunk.length;
          return;
        }

        dataArray.set(chunk.slice(0, FIVE_MB - byteOffset), byteOffset);
        sendPart(dataArray);

        buffer = new ArrayBuffer(FIVE_MB);
        dataArray = new Uint8Array(buffer);
        dataArray.set(chunk.slice(FIVE_MB - byteOffset), 0);
        byteOffset = byteOffset + chunk.length - FIVE_MB;
      },

      async close() {
        if (byteOffset > 0) {
          dataArray = dataArray.slice(0, byteOffset);
          sendPart(dataArray);
        }
        try {
          onRecordingEnded();
          const responses = await Promise.all(partPromises);
          const etags = responses.map((response) => response.headers.get('ETag').replace(/"/g, ''));
          resolve(etags);
        } catch (e) {
          reject(e);
        }
      },

      abort(reason) {
        reject(reason);
      },
    });

    binaryStream.pipeTo(writableStream);
  });
}

export function detectWhenDisabled(stream, callback) {
  stream.getTracks().forEach(
    (track) => track.addEventListener('ended', () => {
      if (stream.getTracks().every((streamTrack) => streamTrack.readyState === 'ended')) {
        callback(stream);
      }
    }),
  );
}
