import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import ConversationSkeleton from '../ConversationSkeleton';
import RecordingItem from '../RecordingItem';
import List from '../../ui/list/List';
import NoDataToShow from '../../ui/ui-feedback/NoDataToShow';
import LoadingComponent from '../../ui/ui-feedback/LoadingComponent';
import { Status } from '../../../utils/constants';
import Recording, { RecordingConversations } from '../../../store/features/recordings/models/Recording';
import RecordingDetail from '../../../containers/RecordingDetail';
import { Todo } from '../../../store/features/todos/types';
import { Appointment } from '../../../store/features/appointments/types';
import TodoModal from '../../../containers/TodoModal';
import AppointmentModal from '../../../containers/AppointmentModal';
import { AppDispatch, RootState } from '../../../store/store';
import { loadFullRecordingDetails, setPageNumber } from '../../../store/features/recordings/actions';

interface ConversationProps {
  conversations: RecordingConversations;
  recordingStatus: string;
  recordingFromURI: Recording | null;
}

function Conversation({
  conversations,
  recordingStatus,
  recordingFromURI,
}: ConversationProps) {
  const conversationsCount = Object.keys(conversations).length;
  const isEmpty = conversationsCount === 0;
  const isLoading = isEmpty && recordingStatus === Status.Loading;
  const isRefreshing = !isEmpty && recordingStatus === Status.Loading;
  const isFailed = !isLoading && !isRefreshing && (recordingStatus === Status.Failed || isEmpty);

  const navigate = useNavigate();
  const [todoPreview, setTodoPreview] = useState<Todo | null>(null);
  const [appointmentPreview, setAppointmentPreview] = useState<Appointment | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const currentPageNumber = useSelector((state: RootState) => state.recordings.pageNumber);
  const reachedEnd = useSelector((state: RootState) => state.recordings.reachedEnd);

  const searchTerm = useSelector((state: RootState) => state.recordings.searchTerm);
  const scrollPosition = useSelector((state: RootState) => state.recordings.scrollPosition);

  const loadNextPage = () => {
    if (isLoading) return;
    const nextPageNumber = currentPageNumber + 1;
    dispatch(setPageNumber({ pageNumber: nextPageNumber }));
    dispatch(loadFullRecordingDetails({ page: nextPageNumber, searchTerm }));
  };

  useEffect(() => {
    dispatch(loadFullRecordingDetails({ page: 1, searchTerm }));
  }, [searchTerm]);

  useEffect(() => {
    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv && scrollPosition && conversationsCount > 1) {
      scrollableDiv.scrollTop = scrollPosition;
    }
  }, []);

  const handlePreviewRecording = useCallback((rec: Recording) => {
    navigate(`/conversations/${rec.sessionId}`);
  }, [navigate]);

  useEffect(() => {
    if (recordingFromURI && !isLoading && !isRefreshing && !isFailed) {
      handlePreviewRecording(conversations[recordingFromURI.sessionId]);
    } else if (!isLoading && !isRefreshing && !isFailed) {
      navigate('/conversations');
    }
  }, [recordingFromURI, conversations, handlePreviewRecording, navigate, isLoading, isRefreshing, isFailed]);

  if (isLoading) {
    return <ConversationSkeleton count={6} />;
  }

  if (isFailed) {
    return <NoDataToShow />;
  }

  const handleTodoClick = (todo: Todo) => {
    const foundTodo = conversations[todo.session_id]?.todos.find((item) => item.id === todo.id);
    if (foundTodo) {
      setTodoPreview(new Todo(foundTodo));
    }
  };

  const handleAppointmentClick = (appointment: Appointment) => {
    const foundAppointment = conversations[appointment.sessionId()]?.appointments.find(
      (item) => item.id === appointment.id(),
    );
    if (foundAppointment) {
      setAppointmentPreview(new Appointment(foundAppointment));
    }
  };

  return (
    <div className="conversations-container">
      {isRefreshing && <LoadingComponent />}
      <InfiniteScroll
        next={loadNextPage}
        hasMore={recordingStatus === Status.Succeeded && !reachedEnd}
        loader={<ConversationSkeleton count={Math.max(6 - conversationsCount, 1)} />}
        dataLength={conversationsCount}
        scrollableTarget="scrollableDiv"
      >
        <List>
          {Object.keys(conversations).map((session) => (
            <RecordingItem
              conversation={conversations[session]}
              key={session}
              onPreviewRecording={handlePreviewRecording}
              onTodoClick={handleTodoClick}
              onAppointmentClick={handleAppointmentClick}
            />
          ))}
        </List>
      </InfiniteScroll>

      {todoPreview && (
        <TodoModal
          todo={todoPreview}
          onClose={() => setTodoPreview(null)}
        />
      )}

      {appointmentPreview && (
        <AppointmentModal
          appointment={appointmentPreview}
          onClose={() => setAppointmentPreview(null)}
        />
      )}
    </div>
  );
}

export default Conversation;
