import * as microsoftTeams from '@microsoft/teams-js';
import { AxiosInstance } from 'axios';
import { fetchUser } from 'store/features/user/userSlice';
import { store } from 'store/store';

export const msAuthErrorPath = '/ms-teams-auth-error';
export const msTeamsTokenKey = 'msTeamsToken';
export const msHostNameKey = 'msHostName';

export const getMsTeamsContext = () => new Promise(
  (resolve) => { microsoftTeams.getContext(resolve); },
);

export const getMsTeamsAuthToken = () => new Promise<string>((resolve, reject) => {
  microsoftTeams.authentication.getAuthToken({
    resources: [],
    claims: [],
    successCallback: resolve,
    failureCallback: reject,
  });
});

const setMsInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.clear();
  axiosInstance.interceptors.request.use(async (config) => {
    const tokenFromStorage = sessionStorage.getItem(msTeamsTokenKey);
    const token = tokenFromStorage || await getMsTeamsAuthToken();

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.MSAuthorization = `Bearer ${token}`;
      if (tokenFromStorage && token !== tokenFromStorage) {
        sessionStorage.setItem(msTeamsTokenKey, token);
      }
    }
    return config;
  });
};

export default async function setupTeamsIfPresent(axiosInstance: AxiosInstance) {
  const url = new URL(window.location.href);
  const tokenFromUrl = url.searchParams.get(msTeamsTokenKey);
  const tokenFromStorage = sessionStorage.getItem(msTeamsTokenKey);
  if (
    url.pathname === msAuthErrorPath
    || (
      window === window.top
      && !tokenFromUrl
      && !tokenFromStorage
    )
  ) {
    return;
  }

  window.inTeams = true;

  if (tokenFromUrl) {
    sessionStorage.setItem(msTeamsTokenKey, tokenFromUrl);
    sessionStorage.setItem(msHostNameKey, url.searchParams.get(msHostNameKey) || '');
    url.searchParams.delete(msTeamsTokenKey);
    url.searchParams.delete(msHostNameKey);
    setMsInterceptors(axiosInstance);
    window.location.replace(url);
    return;
  }

  if (url.searchParams.get(msHostNameKey)) {
    url.searchParams.delete(msHostNameKey);
    window.location.replace(url);
  }

  if (tokenFromStorage) {
    setMsInterceptors(axiosInstance);
  }

  microsoftTeams.initialize();
  console.log('MS Teams context:', await getMsTeamsContext());
  // If we get here, means that we're inside Teams iframe, or in a popup from the Teams web app

  setMsInterceptors(axiosInstance);

  try {
    // waiting a bit here to give the store a chance to fully initialize
    await new Promise((resolve) => { setTimeout(resolve, 100); });

    // TODO create an action and dispatch it instead of this
    setTimeout(function inTeams() {
      if (window.hideLogo) {
        window.hideLogo();
      } else {
        setTimeout(inTeams, 100);
      }
    }, 100);

    const { payload: userData } = await store.dispatch(fetchUser());
    if (userData && ['/', '/login', msAuthErrorPath].includes(window.location.pathname)) {
      window.location.replace('/conversations');
    }
  } catch (e) {
    window.location.replace(msAuthErrorPath);
    console.error('Failed initial /me request', e);
  }
}
