import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Status } from '../../utils/constants';
import { AppDispatch, RootState } from '../../store/store';
import TodoComponent from '../../components/todo/Todo';
import { loadTodos } from '../../store/features/todos/todosSlice';
import { Todo as ITodo } from '../../store/features/todos/types';
import { fetchUser } from '../../store/features/user/userSlice';

interface GroupedTodos {
  [key: string]: {
    [key: string]: {
      meetingName: string;
      todos: ITodo[];
    };
  };
}

interface TodoProps {
  searchTerm: string;
}

function Todo({ searchTerm }: TodoProps) {
  const { t } = useTranslation();

  const [todos, setTodos] = useState<ITodo[] | null>(null);

  const [selectedGroupByValue, setSelectedGroupByValue] = useState<'person' | 'date'>('person');

  const dispatch = useDispatch<AppDispatch>();

  const { data, status } = useSelector((state: RootState) => state.todos);

  const user = useSelector((state: RootState) => state.user.data);

  const userId = user?.id;
  const accountId = useMemo(() => user?.accounts[0].id ?? '', [user?.accounts]);

  useEffect(() => {
    if (status === Status.Succeeded && data) {
      const transformedTodos = data.map((todo) => new ITodo(todo));
      setTodos(transformedTodos);
    }
  }, [data, status]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (accountId) {
      dispatch(loadTodos({ account: accountId, filter: searchTerm, userId }));
    }
  }, [searchTerm, dispatch, userId, accountId]);

  const handleSelectChange = (option: { label: string; value: string }) => {
    const value: 'person' | 'date' = option.value as 'person' | 'date';
    setSelectedGroupByValue(value);
  };

  const groupByOptions = [
    { label: t('person'), value: 'person' },
    { label: t('date'), value: 'date' },
  ];

  const groupedTodos = useMemo(() => {
    if (!todos) return {};

    function groupByPerson(todosData: ITodo[]): GroupedTodos {
      return todosData.reduce((acc: GroupedTodos, todo) => {
        const {
          receiverName: personKey,
          meetingId: meetingKey,
          meetingName,
        } = todo;

        const newAcc = { ...acc };

        if (!newAcc[personKey]) newAcc[personKey] = {};
        if (!newAcc[personKey][meetingKey]) {
          newAcc[personKey][meetingKey] = { meetingName, todos: [] };
        }

        newAcc[personKey][meetingKey].todos.push(todo);

        return newAcc;
      }, {});
    }

    function groupByDate(todosData: ITodo[]): GroupedTodos {
      return todosData.reduce((acc: GroupedTodos, todo) => {
        const dateKey = new Date(todo.meetingStartTime).toDateString();
        const { meetingId: meetingKey, meetingName } = todo;

        const newAcc = { ...acc };

        if (!newAcc[dateKey]) newAcc[dateKey] = {};
        if (!newAcc[dateKey][meetingKey]) newAcc[dateKey][meetingKey] = { meetingName, todos: [] };

        newAcc[dateKey][meetingKey].todos.push(todo);

        return newAcc;
      }, {});
    }

    const groupByFunction = selectedGroupByValue === 'person' ? groupByPerson : groupByDate;

    return groupByFunction(todos);
  }, [todos, selectedGroupByValue]);

  return (
    <TodoComponent
      todos={groupedTodos}
      status={status}
      selectedGroupByValue={selectedGroupByValue}
      groupByOptions={groupByOptions}
      selectGroupByValue={handleSelectChange}
    />
  );
}

export default Todo;
