import React from 'react';

import './navbar.css';

interface NavBarProps {
  activeTab: number;
  tabs: {
    label: string;
    icon: React.ReactNode;
    path: string;
  }[];
  handleTabChange: (index: number) => void;
}

function NavBar({ activeTab, tabs, handleTabChange }: NavBarProps) {
  return (
    <div className="navbar">
      <i className="navbar-color">
        {tabs.map((tab, index) => (
          <button
            type="button"
            key={tab.label}
            className={`nav-item ${activeTab === index ? 'active' : ''}`}
            onClick={() => handleTabChange(index)}
          >
            {tab.icon}
          </button>
        ))}
      </i>
    </div>
  );
}

export default NavBar;
