import { Info } from '../store/features/recordings/models/types';
import { AppointmentData } from '../store/features/appointments/types';

export const mapToInfoType = (data: any): Info => ({
  id: data.id,
  name: data.name,
  meeting_type: data.meeting_type ?? null,
  user: data.user,
  user_full_name: data.user_full_name,
  session_id: data.session_id,
  company_domains: data.company_domains || [],
  participants: data.participants || [],
  thumbnails: data.thumbnails || [],
  date: data.date,
  meeting_time: data.meeting_time,
  slides_shown: data.slides_shown,
  face2face_percentage: data.face2face_percentage,
  direct_share_percentage: data.direct_share_percentage,
  screen_share_percentage: data.screen_share_percentage,
  meeting_score: data.meeting_score,
  meeting_engagement_percentage: data.meeting_engagement_percentage,
  has_recording: data.has_recording,
  finished_merging: data.finished_merging,
  division: data.division,
  shifts_per_minute: data.shifts_per_minute,
  interruptions: data.interruptions,
  session_note: data.session_note ?? null,
  presenter_questions: data.presenter_questions,
  receiver_questions: data.receiver_questions,
  total_questions: data.total_questions,
  questions_per_hour: data.questions_per_hour,
  words_per_minute: data.words_per_minute,
  engaging_questions_per_hour: data.engaging_questions_per_hour,
  live: data.live,
  deal: data.deal || [],
  todos_count: data.todos_count || 0,
  topic: data.topic || '',
  call_direction: data.call_direction || '',
  call: data.call || false,
  private: data.private,
  language: data.language || null,
  screenshots: data.screenshots || null,
  tags: data.tags || null,
  url: data.url || '',
  todos: data.todos || [],
  appointments: data.appointments || [],
});

export const mapToAppointmentData = (data: any): AppointmentData => ({
  id: data.id,
  name: data.name,
  sentence: data.sentence,
  meeting_id: data.meeting_id,
  meeting_name: data.meeting_name,
  meeting_start_time: data.meeting_start_time,
  receiver_id: data.receiver_id,
  receiver_name: data.receiver_name,
  session_id: data.session_id,
  start: data.start,
  end: data.end,
  created_at: data.created_at,
  follow_up_date: data.follow_up_date,
  location: data.location ?? null,
});
